<template>
  <section class="main qrcode_main">
    <v-container grid-list-xl>
      <v-layout column justify-center>
        <v-flex xs12 sm12 md8 lg6 text-center>
          <v-alert class="mb-4" icon="mdi-information" border="left" prominent colored-border type="info">
            <h1 class="title">
              <template v-if="error.statusCode === 404">Page not found</template>
              <template v-else>An error occurred</template>
            </h1>
          </v-alert>
          <v-btn depressed nuxt color="accent" :to="localePath({ name: 'index' })" class="mt-6">
            Home<svg class="v-icon right svg" fill="white"><use xlink:href="#icon-sonbei" /></svg>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  created() {
    console.error(this.error)
  }
}
</script>
