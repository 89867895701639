<template>
  <header class="header_top">
    <v-container grid-list-md class="toolbar_container">
      <v-layout row align-center justify-space-between>
        <v-flex shrink>
          <h1 class="header_top--title">
            <nuxt-link :to="localePath({ name: 'index' })" title="Sonbei">
              <svg class="header_top--icon"><use xlink:href="#icon-logo" /></svg>
              <svg class="header_top--icon_small"><use xlink:href="#icon-sonbei" /></svg>
              <strong class="seo">Sonbei</strong>
            </nuxt-link>
          </h1>
        </v-flex>
        <v-flex shrink>
        </v-flex>
      </v-layout>
    </v-container>
  </header>
</template>

<script>
export default {
  data: () => ({}),
}
</script>
