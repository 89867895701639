<template>
  <article class="hero">
    <div class="hero--body">
      <section class="container">
        <h2 class="hero--title">
          <strong>
            Find &amp; Connect<br />with Artists
            <small>around you</small>
          </strong>
        </h2>
      </section>
    </div>

    <picture v-if="$mq === 'sm'">
      <source srcset="/img/poster.webp" type="image/webp" />
      <img class="hero--bg" src="/img/poster.jpg" alt="hero" />
    </picture>
    <video v-else class="hero--video" muted autoplay loop src="/video/sonbei_live.mp4?t=0.1s" poster="/img/poster.jpg"></video>
    <div class="hero--video--overlay"></div>

    <span class="hero--scroll"></span>
  </article>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  computed: {
    isJA() {
      return this.$i18n.locale === 'ja'
    },
    isEN() {
      return this.$i18n.locale === 'en'
    }
  }
}
</script>
