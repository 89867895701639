<template>
  <v-dialog v-model="active" fullscreen persistent background="black" transition="false">
    <v-container fill-height fluid class="loaderHolder">
      <v-layout row justify-center align-center>
        <!-- <v-progress-circular indeterminate :size="70" :width="7" color="white"></v-progress-circular> -->
        <div class="loader medium">
          <ul class="loader--circle">
            <li class="loader--bar" />
            <li class="loader--bar" />
            <li class="loader--bar" />
            <li class="loader--bar" />
          </ul>
        </div>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({})
}
</script>
<style lang="scss" scoped>
.loaderHolder {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  text-align: center;
}
</style>
