export const vueI18n = {"fallbackLocale":"en","messages":{"en":{"message":{"contact_us_now":"Contact us now","contact_us_now_message":"You have a question<br />or want to collaborate with Sonbei?","what_is_sonbei_title":"Sonbei is","what_is_sonbei_subtitle":"An app for music lovers","what_is_sonbei_text":"The world has a lot to offer and it might get complicated to get around all the cool artists around here. We created an app where artists can connect with each others, share with their fans, and get together for exciting events.","back":"Back","cancel":"Cancel","confirm":"OK"}},"ja":{"message":{"contact_us_now":"問合せする","contact_us_now_message":"質問・要望がありましたら<br />お気軽にお問い合わせください","what_is_sonbei_title":"SONBEIは","what_is_sonbei_subtitle":"音楽を愛する人たちのアプリです","what_is_sonbei_text":"世界は広い。だから日本中のかっこいいアーティストを見つけるのは簡単じゃない。そこで、アーティスト同士が繋がれたり、ファンと情報を共有できたり、エキサイティングなイベントを開催できる、そんなアプリを創りました！","back":"戻る","cancel":"キャンセル","confirm":"OK"}}}}
export const vueI18nLoader = false
export const locales = [{"code":"en","iso":"en-US"},{"code":"ja","iso":"ja-JP"}]
export const defaultLocale = 'ja'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = false
export const langDir = null
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieDomain":null,"cookieKey":"sonbei_i18n_redirected","alwaysRedirect":false,"fallbackLocale":null}
export const differentDomains = false
export const forwardedHost = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const encodePaths = true
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en","ja"]
