<template>
  <v-app id="sonbei_theme" class="sonbei_theme">
    <svg-sprites />
    <base-header />

    <v-content>
      <nuxt />
    </v-content>

    <base-footer />
    <the-loader :active="isLoading" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgSprites from '~/components/commons/SvgSprites'
import BaseHeader from '~/components/molecules/headers/BaseHeader'
import BaseFooter from '~/components/molecules/footers/BaseFooter'
import TheLoader from '~/components/commons/TheLoader'

export default {
  components: { TheLoader, BaseFooter, BaseHeader, SvgSprites },
  computed: {
    ...mapGetters(['isLoading'])
  },
  head() {
    return { htmlAttrs: { lang: 'ja' } }
  }
}
</script>

<style lang="scss"></style>
