<template>
  <footer class="footer">
    <v-container grid-list-xl>
      <v-layout column>
        <v-flex class="text-center">
          <nuxt-link :to="localePath({ name: 'index' })" title="Sonbei">
            <svg class="footer--icon"><use xlink:href="#icon-logo" /></svg>
            <svg class="footer--icon_small"><use xlink:href="#icon-sonbei" /></svg>
            <strong class="seo">Sonbei</strong>
          </nuxt-link>
        </v-flex>

        <v-flex xs12 sm12>
          <small class="footer--credits">© Sonbei {{ todayYear }}, all rights reserved</small>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout row justify-center>
        <v-flex xs12 sm12 md3 class="text-center pa-4">
          <nuxt-link class="link" :to="localePath({ name: 'terms' })" title="Sonbei">
            Terms &amp; Condition
          </nuxt-link>
        </v-flex>
        <v-flex xs12 sm12 md3 class="text-center pa-4">
          <nuxt-link class="link" :to="localePath({ name: 'privacy-policy' })" title="Sonbei">
            Privacy Policy
          </nuxt-link>
        </v-flex>
      </v-layout>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'FooterTag',
  computed: {
    todayYear() {
      const d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
