<template>
  <v-app-bar color="white" dark fixed app class="console_toolbar">
    <v-container grid-list-md class="toolbar_container">
      <v-layout row align-center justify-space-between>
        <v-flex shrink>
          <h1 class="header--title">
            <nuxt-link :to="localePath({ name: 'index' })" title="Sonbei">
              <svg class="header--icon"><use xlink:href="#icon-logo" /></svg>
              <svg class="header--icon_small"><use xlink:href="#icon-sonbei" /></svg>
              <strong class="seo">Sonbei</strong>
            </nuxt-link>
          </h1>
        </v-flex>
        <v-flex shrink>
          <client-only>
            <v-card-actions>
              <v-btn v-for="locale in availableLocales" :key="locale.code" light text @click="switchLang">
                <v-icon small left>
                  mdi-translate
                </v-icon>
                {{ locale.code }}
              </v-btn>
            </v-card-actions>
          </client-only>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    }
  },
  methods: {
    switchLang() {
      this.$i18n.setLocale(this.$i18n.locale === 'ja' ? 'en' : 'ja')
      this.switchLocalePath(this.$i18n.locale === 'ja' ? 'en' : 'ja')
    }
  }
}
</script>
