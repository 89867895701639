// import ja from 'vuetify/src/locale/ja'
import colors from 'vuetify/es5/util/colors'
export default {
  breakpoint: {},
  icons: {},
  // rtl: true,
  theme: {
    dark: false,
    themes: {
      light: {
        primary: {
          base: '#00adb5',
          lighten1: '#4d73c7',
          lighten2: '#b2bdd7',
          lighten3: '#d9e0ee',
          lighten4: '#f1f4f9',
          lighten5: '#eaedf4',
          lighten6: '#2f60e9',
          lighten7: '#b3bed7',
          darken1: '#002fb2',
          darken3: '#002fb2'
        },
        secondary: {
          base: '#ef9c00',
          darken3: 'd07100'
        },
        accent: {
          base: '#f7cf15',
          lighten3: '#fffbdb',
          darken3: '#f7cf15'
        },
        info: {
          base: '#228ab3',
        },
        warning: '#fb8c00',
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
        grey: {
          // base: '#',
          // lighten1: '#',
          // lighten2: '#',
          // lighten3: '#',
          // lighten4: '#',
          // lighten5: '#',
          // darken1: '#',
          // darken2: '#',
          // darken3: '#',
          // darken4: '#',
          darken5: '#1c1c1c'
        }
      }
    },
    options: {
      customProperties: true
    }
  }
}
