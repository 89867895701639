<template>
  <v-app id="sonbei_theme" class="sonbei_theme wrapper_top">
    <svg-sprites />
    <top-header />
    <top-hero />
    <nuxt />
    <the-loader :active="isLoading" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgSprites from '~/components/commons/SvgSprites'
import TopHeader from '~/components/molecules/headers/TopHeader'
import TopHero from '~/components/molecules/heroes/TopHero'
import TheLoader from '~/components/commons/TheLoader'

export default {
  components: { TheLoader, TopHero, TopHeader, SvgSprites },
  computed: {
    ...mapGetters(['isLoading'])
  },
}
</script>

<style lang="scss"></style>
