import Vuex from 'vuex'

const getDefaultState = () => {
  return {
    // events
    isLoading: false,
    global_modal: false
  }
}

const createStore = () => {
  return new Vuex.Store({
    state() {
      return getDefaultState()
    },
    getters: {
      isLoading: (state) => state.isLoading
    },
    mutations: {},
    actions: {},
    modules: {}
  })
}

export default createStore
